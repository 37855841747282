

import __layout_0 from '/home/runner/work/site/site/src/layouts/default.vue'
export const layouts = {
'Footer': () => import('/home/runner/work/site/site/src/layouts/Footer.vue'),
'Header': () => import('/home/runner/work/site/site/src/layouts/Header.vue'),
'Inscription': () => import('/home/runner/work/site/site/src/layouts/Inscription.vue'),
'MoulinetteHeaderFooter': () => import('/home/runner/work/site/site/src/layouts/MoulinetteHeaderFooter.vue'),
'default': __layout_0,
}

function generateLayout (nestedLayouts, route, root = false) {
  const firstLayout = nestedLayouts.shift()
  const path = root ? route.path : ''
  return nestedLayouts.length
    ? {
      path,
      component: layouts[firstLayout],
      children: [generateLayout(nestedLayouts, route)]
    }
    : {
      path,
      component: layouts[firstLayout],
      children: [{ ...route, path: '' }]
    }
}

export function setupLayouts (routes) {
  return routes.map((route) => {
    return generateLayout((route.meta?.layout || 'default').split(':'), route, true)
  })
}
