import { kapixContext } from 'kapix-components-vue3'
import { env, envIsStrictProduction } from '~/constants/env'

export function consoleDebug (message: Nullable<string>, args: any = undefined, type: 'log' | 'warn' | 'error' = 'log') {
  if (env.MODE === 'test') return
  if (kapixContext.isClient) {
    const isProduction: boolean = envIsStrictProduction()

    if (!isProduction || (isProduction && type === 'error')) {
    // eslint-disable-next-line no-console
      args ? console[type](message, args) : console[type](message)
    }
  }
}

export function isMaintenance (): boolean {
  const urlParams = new URLSearchParams(window.location.search)

  if (urlParams.has('maintenance') && urlParams.get('maintenance') === 'false') {
    return false
  }
  else {
    return true
  }
}
