// import VueMatomo from 'vue-matomo'
import { envIsStrictProduction } from '~/constants/env'
import type { UserModule } from '~/types'

export const install: UserModule = async ({ app, router, isClient }) => {
  if (!isClient || !envIsStrictProduction()) {
    return
  }

  const VueMatomo = await import(('vue-matomo')) as any

  // Children doesn't render properly : à implémenter autrement
  // head?.addHeadObjs({
  //   noscript: [
  //     {
  //       children: '<p><img src="https://matchers.matomo.cloud/matomo.php?idsite=1&amp;rec=1" style="border:0;" alt=""></img></p>'
  //       // body: true
  //     }
  //   ]
  // })

  app.use(VueMatomo.default, {
    host: 'https://matchers.matomo.cloud',
    siteId: 1,
    trackerFileName: 'matomo',

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://m.matchers.fr/matomo.js',

    // Overrides the autogenerated tracker script path entirely
    // Default: undefined
    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

    router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    requireCookieConsent: false,
    enableHeartBeatTimer: true,
    heartBeatTimerInterval: 20,
    debug: false,
    userId: undefined,
    cookieDomain: '*.matchers.fr',
    domains: '*.matchers.fr',
    preInitActions: [],
    trackSiteSearch: (to: any) => {
      if (to.query.query && to.name === 'experts') {
        return { keyword: to.query.query }
      }
      else {
        return null
      }
    },
    crossOrigin: undefined
  })
}

// // Now you can access piwik api in components through
// this.$matomo (guard avec this.$matomo && this.$matomo.property)

// // or
// window._paq.push

// // or through
// window.Piwik.getTracker
