import type { Provider, Session, User } from '@supabase/gotrue-js'
import { defineStore } from 'pinia'
import { useToast } from 'kapix-components-vue3'
import type { AuthResponse } from '@supabase/supabase-js'
import { supabase } from '~/services/supabase/supabase'
import { consoleDebug } from '~/helpers'
export enum SearchEngineAuthEnum {
  VIEW_PRIVATE
}
export enum MoulinetteEnum {
  VIEW_PRIVATE
}

interface AdminAuthorizations {
  searchEngine?: SearchEngineAuthEnum
  moulinette?: MoulinetteEnum
}

export const useAuthStore = defineStore('auth', {
  state: () => {
    return {
      session: undefined as Session | null | undefined,
      returnUrl: ''
    }
  },
  getters: {
    user (): User | null {
      if (this.session?.user) return this.session.user
      return null
    },
    isUserLoggedIn (): boolean {
      return !!this.user
    },
    isSessionValid (): boolean {
      return !!(this.session?.expires_at && this.session?.expires_at < Date.now())
    },
    getExpertOnboardingStep (): number {
      return this.user?.user_metadata?.ExpertOnboardingStep || 0
    },
    hasAuthorization (): AdminAuthorizations {
      const adminAuth: AdminAuthorizations = {}
      const isExpertAdmin = this.user?.user_metadata?.isExpertAdmin && this.user?.role === 'admin_expert'
      const isSuperAdmin = this.user?.user_metadata?.isSuperAdmin && this.user?.role === 'admin_super'

      if (isExpertAdmin || isSuperAdmin) {
        adminAuth.searchEngine = SearchEngineAuthEnum.VIEW_PRIVATE
        adminAuth.moulinette = MoulinetteEnum.VIEW_PRIVATE
      }

      return adminAuth
    }
  },
  actions: {
    async loginWithEmailAndPassword (email: string, password: string) {
      const resp = await supabase.auth.signInWithPassword({ email, password })
      if (resp.error) consoleDebug(resp.error.message, '', 'error')
      this.initLoginData(email, resp)
      return resp
    },
    initLoginData (email: string, resp: AuthResponse) {
      const getMatchers = email.split('@')
      if (resp.data.user?.aud === 'authenticated' && !(email.includes('+')) && getMatchers[1] === 'matchers.fr') {
        sessionStorage.setItem('is_matchers', 'is_Matchers')
      }
    },
    async signUpWithEmailAndPassword (email: string, password: string, metaData: {}) {
      const resp = await supabase.auth.signUp({ email, password, options: { data: metaData } })
      if (resp.error) consoleDebug('Error :', resp.error.message, 'error')
      return resp
    },

    async loginWithMagicLink (email: string) {
      const resp = await supabase.auth.signInWithOtp({ email })
      if (resp.error) consoleDebug(resp.error.message, '', 'error')
      this.initLoginData(email, resp)
      return resp
    },

    async signUpWithMagicLink (email: string) {
      const resp = await supabase.auth.signInWithOtp({ email })
      if (resp.error) consoleDebug(resp.error.message, '', 'error')
      return resp
    },

    // async signUpWithSocialAccount (provider: Provider) {
    //   const resp = await supabase.auth.signInWithOAuth({ provider })
    //   if (resp.error) consoleDebug(resp.error.message,'' , 'error')
    //   return resp
    // },

    async loginWithSocialAccount (provider: Provider) {
      const resp = await supabase.auth.signInWithOAuth({ provider })
      if (resp.error) consoleDebug(resp.error.message, '', 'error')
      return resp
    },

    // async redirectAfterLogin() {
    //   consoleDebug(this.returnUrl)
    //   if (this.returnUrl) router.push(this.returnUrl)
    //   else router.push({name: 'App'})
    // },

    async getRecoveryPasswordLink (email: string) {
      const resp = await supabase.auth.resetPasswordForEmail(email)
      if (resp.error) consoleDebug(resp.error.message, '', 'error')
      return resp
    },

    async updatePassword (password: string) {
      const resp = await supabase.auth.updateUser({ password })
      if (resp.error) consoleDebug(resp.error.message, '', 'error')
      return resp
    },

    async logout () {
      const toast = useToast()
      const resp = await supabase.auth.signOut()
      if (resp.error) consoleDebug(resp.error.message, '', 'error')
      else {
        toast.success('Déconnecté.e avec succès')
        sessionStorage.clear()
      }
      return resp
    }
  }
})
