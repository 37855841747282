import { forOwn } from 'lodash-es'

export function getFullUrlPath (location: any) {
  const isHttps = location.protocol === 'https:'
  return `${location.protocol}//${location.hostname
       }:${location.port || (isHttps ? '443' : '80')
       }${/^\//.test(location.pathname) ? location.pathname : `/${location.pathname}`}`
}

export function parseQueryString (str: string) {
  const obj: any = {}
  let key
  let value;
  (str || '').split('&').forEach((keyValue) => {
    if (keyValue) {
      value = keyValue.split('=')
      key = decodeURIComponent(value[0])
      obj[key] = (value[1]) ? decodeURIComponent(value[1]) : true
    }
  })
  return obj
}

function popupwindow (url: string, title: string, w: number, h: number, options: string) {
  const y = window.outerHeight / 2 + window.screenY - (h / 2)
  const x = window.outerWidth / 2 + window.screenX - (w / 2)

  return window.open(
    url,
    title,
    `${options}, toolbar=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`)
}

export default class OAuthPopup {
  public popup: any
  public url: any
  public name: any
  public popupOptions: any
  constructor (url: string, name: string, popupOptions: string) {
    this.popup = null
    this.url = url
    this.name = name
    this.popupOptions = popupOptions
  }

  open (redirectUri: string, skipPooling?: any) {
    try {
      this.popup = popupwindow(this.url, this.name, 350, 500, this._stringifyOptions())
      if (this.popup && this.popup.focus) {
        this.popup.focus()
      }

      if (skipPooling) {
        return Promise.resolve()
      }
      else {
        return this.pooling(redirectUri)
      }
    }
    catch (e) {
      return Promise.reject(new Error(`OAuth popup error occurred ${e}`))
    }
  }

  pooling (redirectUri: string) {
    return new Promise((resolve, reject) => {
      const redirectUriParser = document.createElement('a')
      redirectUriParser.href = redirectUri
      // const redirectUriPath = getFullUrlPath(redirectUriParser)

      let poolingInterval = setInterval(() => {
        const { popup } = this
        if (!popup || popup.closed || popup.closed === undefined) {
          clearInterval(poolingInterval)
          // @ts-ignore
          poolingInterval = null
          return reject(new Error('Auth popup window closed'))
        }
        popup.focus && popup.focus()
        try {
          const popupWindowPath = getFullUrlPath(popup.location)
          if (popupWindowPath.includes('succeeded')) {
            if (popup.location.search || popup.location.hash) {
              const query = parseQueryString(popup.location.search.substring(1).replace(/\/$/, ''))
              const hash = parseQueryString(popup.location.hash.replace('#/', '').substring(1).replace(/[/$]/, ''))
              const params = {
                ...query,
                ...hash
              }
              if (params.error) {
                reject(new Error(params.error))
              }
              else {
                resolve(params)
              }
            }
            // resolve('token')
            // else {
            //   reject(new Error('OAuth redirect has occurred but no query or hash parameters were found.'))
            // }
            clearInterval(poolingInterval)
            // @ts-ignore
            poolingInterval = null
            popup.close()
          }
        }
        catch (e) {
          // debugger
          // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
        }
      }, 100)
    })
  }

  _stringifyOptions () {
    const array: string[] = []
    forOwn(this.popupOptions, (options, optionKey) => {
      if (options !== undefined) {
        array.push(`${optionKey}=${options}`)
      }
    })
    return array.join(',')
  }
}
