import type { CustomStoreImplementationOptions, MergeCustomStore, StoreIdentifier } from 'kapix-components-vue3'
import { defineStore } from 'pinia'
import type { RouteLocationNormalized } from 'vue-router'
import axios from 'axios'
import { initAliveStoreIds, kapixContext, navigateTo, useToast } from 'kapix-components-vue3'
import appAuthenticationStoreStoreCustom from './appAuthentication.custom'
import OAuthPopup from '~/helpers/popup'
import type { AuthProvider } from '~/helpers/types'
import { $translate } from '~/modules/i18n'
import { backendAxiosInstance, getConnectedUser, registerTokenInfoLocalStorage, serverLogoutUser, tokenInfo } from '~/helpers/server'
import { baseUrl } from '~/constants'
import { getRedirectUri } from '~/helpers/http'

const storeName = 'appAuthentication'
const customImplement: CustomStoreImplementationOptions = appAuthenticationStoreStoreCustom.options
const aliveStoreIds = initAliveStoreIds()

function appAuthenticationStoreFactory (storeId?: Nullable<StoreIdentifier>) {
  return defineStore(storeId == null ? storeName : `${storeName}/${storeId}`, {
    state: () => {
      return {
        $aliveStoreIds: aliveStoreIds,
        $subStoreIds: 1,
        $storeId: storeId,
        user: undefined as PartialEntity<Kapix.Entity.IUser>,
        ...(customImplement.state && customImplement.state()),
        initialized: false,
        initializing: false,
        initializePromise: null as Nullable<Promise<void>>
      }
    },
    getters: {
      /* @ts-ignore: to allow override in your custom file */
      isAuthenticated: (state) => {
        return !!state.user
      },
      /* @ts-ignore: to allow override in your custom file */
      userEmail: (state) => {
        return state.user?.email
      },
      /* @ts-ignore: to allow override in your custom file */
      userName: (state) => {
        return state.user?.userName
      },
      ...customImplement.getters
    },
    actions: {
      /* @ts-ignore: to allow override in your custom file */
      async init (homePath: string, connectionPath: string, to?: RouteLocationNormalized) {
        if (!this.initialized) {
          this.initializing = true
          const onEnd = async (resolve: (value: void | PromiseLike<void>) => void, success: boolean) => {
            resolve()
            this.initializing = false
            if (to) {
              if (success) {
                if (to.path === '/' || to.path === connectionPath) {
                  navigateTo({
                    path: homePath
                  })
                }
                else if (to.meta.requiresAuth) {
                  navigateTo(to)
                }
              }
              else if (to.meta.requiresAuth) {
                navigateTo('#login')
              }
            }
          }
          const initializePromise = new Promise<void>((resolve) => {
            const { hasValidAccessToken } = tokenInfo
            if (hasValidAccessToken) {
              this.fetchUserInfo()
                .then(() => onEnd(resolve, true))
                .catch(() => onEnd(resolve, false))
            }
            else {
              onEnd(resolve, false)
            }
          })
          this.initializePromise = initializePromise
          this.initialized = true
        }
        await this.initializePromise
      },
      /* @ts-ignore: to allow override in your custom file */
      async logout (serverLogout = true) {
        serverLogoutUser(serverLogout)
        this.user = null
        await navigateTo('#login')
      },
      /* @ts-ignore: to allow override in your custom file */
      async fetchUserInfo () {
        const serverUser = await getConnectedUser()
        this.user = serverUser && {
          ...serverUser
        }
      },
      /* @ts-ignore: to allow override in your custom file */
      async registerLoginWithEmail (email: Nullable<string>, password: Nullable<string>, option: string, navigateToUrl?: Nullable<KeyValuePair>) {
        const params = {
          email,
          password
        }

        if (!email) {
          useToast().warning($translate('authentication.warnings.email', 'Please fill in an email'))
          return
        }
        else if (!password) {
          useToast().warning($translate('authentication.warnings.password', 'Please fill in a password'))
          return
        }

        const response = await backendAxiosInstance.post(`${baseUrl}/auth/email/${option}`, params)
        if (response.data.success === false) {
          useToast().warning(response.data.message)
          return false
        }
        const { accessToken, refreshToken } = response.data
        if (accessToken) {
          registerTokenInfoLocalStorage(accessToken, refreshToken)
          await this.fetchUserInfo()
          const { $route } = kapixContext
          const redirect_uri = $route?.query.redirect_uri
          if (redirect_uri) {
            await navigateTo({ path: redirect_uri }, true)
          }
          else if (navigateToUrl) {
            await navigateTo(navigateToUrl)
          }
        }
      },
      /* @ts-ignore: to allow override in your custom file */
      async loginWithProvider (provider: AuthProvider, navigateToUrl?: Nullable<KeyValuePair>) {
        const url = [`${baseUrl}/auth/${provider}/login`, `redirectUri=${getRedirectUri()}/api/auth/${provider}/succeeded`].join('?')
        const oauthPopup = new OAuthPopup(url, provider, '')
        const response = await oauthPopup.open(`${baseUrl}/auth/${provider}/redirect`) as any
        const { accessToken, refreshToken } = response
        if (accessToken) {
          registerTokenInfoLocalStorage(accessToken, refreshToken)
          await this.fetchUserInfo()
          useToast().success($translate('authentication.success', 'Connection successful'))
          const { $route } = kapixContext
          const redirect_uri = $route?.query.redirect_uri
          if (redirect_uri) {
            await navigateTo({ path: redirect_uri }, true)
          }
          else if (navigateToUrl) {
            await navigateTo(navigateToUrl)
          }
        }
      },
      /* @ts-ignore: to allow override in your custom file */
      async refresh () {
        const response = await axios.post(`${baseUrl}/auth/refresh`, { email: this.userEmail }, {
          headers: {
            'Authorization': tokenInfo.bearerRefreshAuthorization,
            'Content-Type': 'application/json'
          }
        })
        const { accessToken, refreshToken } = response.data
        if (accessToken) {
          registerTokenInfoLocalStorage(accessToken, refreshToken)
        }
        return response.status
      },
      /* @ts-ignore: to allow override in your custom file */
      async status () {
        const response = await backendAxiosInstance.get(`${baseUrl}/auth/status`)
        if (response && response.data) {
          useToast().success(response.data)
        }
      },
      /* @ts-ignore: to allow override in your custom file */
      async sendEmailResetPassword (email: string) {
        const params = {
          email
        }

        const response = await axios.post(`${baseUrl}/auth/email/reset/sendEmail`, params)

        useToast().success(response.data.message)
      },
      /* @ts-ignore: to allow override in your custom file */
      async resetPassword (password: string, otp: string) {
        const params = {
          password,
          otp
        }

        const response = await axios.post(`${baseUrl}/auth/email/reset/forgot`, params)

        if (response.data.success === true) {
          useToast().success(response.data.message)
        }
        else {
          useToast().warning(response.data.message)
        }
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        return storeId != null ? getStoreInstance(storeId) : this
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstances () {
        return aliveStoreIds.map(storeId => this.getStoreInstance(storeId))
      },
      /* @ts-ignore: to allow override in your custom file */
      newStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        const newStoreId = storeId || this.$subStoreIds++
        if (aliveStoreIds.includes(newStoreId)) {
          throw new Error(`Store with id ${storeId} already exists`)
        }
        aliveStoreIds.push(newStoreId)
        return getStoreInstance(newStoreId)
      },
      ...customImplement.actions
    }
  })
}

function getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
  return appAuthenticationStoreFactory(storeId)()
}

export const appAuthenticationStoreRaw = appAuthenticationStoreFactory()
export const appAuthenticationStore = () => appAuthenticationStoreRaw() as MergeCustomStore<typeof appAuthenticationStoreStoreCustom.instance>
