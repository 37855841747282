import type { UserModule } from '~/types'
// // Import the CSS or use your own!

export const install: UserModule = async ({ app, isClient }) => {
  if (isClient) {
    const modal = await import('vue-final-modal') as any
    app.use(modal.vfmPlugin)
  }
  else {
    app.component('VueFinalModal', () => 'div')
  }
}
