import { env } from '~/constants/env'
import type { UserModule } from '~/types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const install: UserModule = ({ app, router, isClient }) => {
  if (isClient && env.MODE === 'production') {
    const d = document
    const g = d.createElement('script')
    const s = d.getElementsByTagName('script')[0]
    g.type = 'text/javascript'
    g.id = 'hs-script-loader'
    g.async = true
    g.defer = true
    g.src = '//js.hs-scripts.com/4939737.js'
    s.parentNode!.insertBefore(g, s)
  }
}

