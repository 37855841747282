import type { UserModule } from '~/types'
import { supabase } from '~/services/supabase/supabase'
import { useAuthStore } from '~/stores/auth'

export const install: UserModule = (ctx) => {
  const authStore = useAuthStore()
  supabase.auth.onAuthStateChange((event, session) => {
    // consoleDebug('ON AUTH STATE CHANGE, event:', event)
    // consoleDebug('ON AUTH STATE CHANGE, session:', session)
    // consoleDebug('ON AUTH STATE CHANGE, returnUrl:', authStore)
    if (session) authStore.session = session
    if (event === 'SIGNED_OUT') {
      authStore.session = null
      ctx.router.push({ path: authStore.returnUrl || '/' })
    }
  })
}
