import type { CustomStoreImplementationOptions, KaElementMap, MergeCustomStore, StoreIdentifier } from 'kapix-components-vue3'
import { defineStore } from 'pinia'
import { initAliveStoreIds, kapixContext, navigateTo, remove, scrollInfo } from 'kapix-components-vue3'
import blogStoreCustom from './store.custom'
import { $translate } from '~/modules/i18n'

const storeName = 'blog'
const customImplement: CustomStoreImplementationOptions = blogStoreCustom.options
const aliveStoreIds = initAliveStoreIds()

function blogFactory (storeId?: Nullable<StoreIdentifier>) {
  return defineStore(storeId == null ? storeName : `${storeName}/${storeId}`, {
    state: () => {
      return {
        $aliveStoreIds: aliveStoreIds,
        $subStoreIds: 1,
        $storeId: storeId,
        $emit: (() => {}) as (propName: any, value: any) => void,
        $elements: {} as KaElementMap,
        $router: kapixContext.isClient ? kapixContext.$router : undefined,
        $route: kapixContext.isClient ? kapixContext.$route : undefined,
        // Page info,
        $title: $translate('hub-blog.title', 'Blog de la formation professionnelle | Matchers'),
        $description: $translate('hub-blog.description', 'Retrouvez ici toutes les informations spécifiques et qualitatives de la formation professionnelle utiles pour votre entreprise !'),
        $publishedTime: new Date('2023-04-14T18:16:27.480Z'),
        $modifiedTime: new Date('2023-11-09T21:38:29.701Z'),
        // Constants,
        $constants: {
          blocPreviewRessourceTitle: '<p style="margin-left:0px;"><span style="color:#1100A2FF;">Comment faire un <strong>plan de formation</strong> ?</span></p>',
          element1Title: '<p style="margin-left:0px;"><span style="color:#1100A2FF;"><strong>Le budget formation des PME : </strong>une ressource méconnue</span></p>',
          element4Text: '<p><span style="background-color:transparent;color:#000000;">Cette prime porte beaucoup (trop) de noms ! Aide à la fonction de maître d’apprentissage, prime tutorale ou prime de tutorat, AEFT, AEFMA...</span></p>'
        },
        // Data,
        $info: { hubBlog: scrollInfo() },
        ...(customImplement.state && customImplement.state())
      }
    },
    getters: {
      ...customImplement.getters
    },
    actions: {
      /* @ts-ignore: to allow override in your custom file */
      async lienClick () {
        await navigateTo({ name: 'Blog-Entreprise-prime-tutorale-atlas' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async conteneur2Click () {
        await navigateTo({ name: 'Blog-Entreprise-prime-tutorale-atlas' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async blocPreviewRessourceClick () {
        await navigateTo({ name: 'Blog-plan-de-formation' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async element1Click () {
        await navigateTo({ name: 'Blog-Entreprise-budget-formation-pme' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async element2Click () {
        await navigateTo({ name: 'Blog-prime-tutorale-opco-afdas' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async element3Click () {
        await navigateTo({ name: 'Entreprise-Blog-Page5-financements-pme' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async element4Click () {
        await navigateTo({ name: 'Blog-prime-tutorale-opcommerce' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async element5Click () {
        await navigateTo({ name: 'Entreprise-Blog-formation-professionnelle-fonctionnement' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async element6Click () {
        await navigateTo({ name: 'Blog-Entreprise-prime-tutorale-atlas' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async element8Click () {
        await navigateTo({ name: 'Entreprise-Blog-cufpa-et-contribution-conventionnelle-opco-atlas' })
      },
      /* @ts-ignore: to allow override in your custom file */
      async element9Click () {
        await navigateTo({ name: 'Blog-definition-opco' })
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        return storeId != null ? getStoreInstance(storeId) : this
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstances () {
        return aliveStoreIds.map(storeId => this.getStoreInstance(storeId))
      },
      /* @ts-ignore: to allow override in your custom file */
      newStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        const newStoreId = storeId || this.$subStoreIds++
        if (aliveStoreIds.includes(newStoreId)) {
          throw new Error(`Store with id ${storeId} already exists`)
        }
        aliveStoreIds.push(newStoreId)
        return getStoreInstance(newStoreId)
      },
      ...customImplement.actions,
      /* @ts-ignore: to allow override in your custom file */
      beforeMount () {
        customImplement.actions?.beforeMount?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      mounted () {
        customImplement.actions?.mounted?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      async serverPrefetch () {
        await customImplement.actions?.serverPrefetch?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      beforeUnmount () {
        customImplement.actions?.beforeUnmount?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      unmounted () {
        if (this.$storeId != null) {
          this.$dispose()
          remove(aliveStoreIds, this.$storeId)
        }
        customImplement.actions?.unmounted?.call(this)
      }
    }
  })
}

function getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
  return blogFactory(storeId)()
}

export const blogRaw = blogFactory()
export const blog = () => blogRaw() as MergeCustomStore<typeof blogStoreCustom.instance>
