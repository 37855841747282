import type { RouteLocationNormalized, RouteRecordName } from 'vue-router'
import { isString } from 'lodash-es'
import { useToast } from 'kapix-components-vue3'
import { supabase } from '../services/supabase/supabase'
import { useAuthStore } from '~/stores/auth'
import type { UserModule } from '~/types'
import { consoleDebug } from '~/helpers'

export const install: UserModule = ({ router, isClient }) => {
  const authStore = useAuthStore()
  const checkSessionStorage = isClient && window && window.sessionStorage
  let fromMoulinette = {} as any

  router.onError((error) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      const toast = useToast()
      toast.warning('Une nouvelle version du site est disponible, la page va se recharger')
      setTimeout(() => {
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href
        // Recharge la page en forçant le serveur à refournir la page
      }, 5000)
    }
  })

  if (checkSessionStorage) {
    fromMoulinette = sessionStorage.getItem('fromMoulinette')
  }
  router.beforeEach(async (to, from) => {
    const regex = /moulinette/
    if (regex.test(from.path) && checkSessionStorage) {
      sessionStorage.setItem('fromMoulinette', from.path)
    }
    else if (from.path !== '/connexion' && fromMoulinette !== null && checkSessionStorage) {
      sessionStorage.removeItem('fromMoulinette')
    }
    let sessionResponse
    if (!authStore.session || !authStore.isSessionValid) sessionResponse = await supabase.auth.getSession()
    if (sessionResponse?.data.session) authStore.session = sessionResponse.data.session
    const step = authStore.getExpertOnboardingStep
    consoleDebug(`step is: ${step}`)
    const toPathLevels = to.path.split('/')
    const toApp = toPathLevels[1].toLocaleLowerCase() === 'app'
    consoleDebug(`toApp is: ${toApp}`)
    const toExpert = toPathLevels[2] ? toPathLevels[2].toLocaleLowerCase() === 'expert' : false
    consoleDebug(`toExpert is: ${toExpert}`)
    const toClient = toPathLevels[2] ? toPathLevels[2].toLocaleLowerCase() === 'client' : false
    consoleDebug(`toClient is: ${toClient}`)
    const fromPathLevels = from.path.split('/')
    const fromApp = fromPathLevels[1].toLocaleLowerCase() === 'app'
    consoleDebug(`fromApp is: ${fromApp}`)
    const toConnexion = toPathLevels[1].toLocaleLowerCase() === 'connexion'
    consoleDebug(`toConnexion is: ${toConnexion}`)

    /* La loguique Provider */
    // // On check si on vient de la connexion par provider
    // const isFromProvider = to.fullPath.split('/')[1].split('=')[0] === '#access_token'
    // if (isFromProvider) {
    //   return { name: 'Connexion', query: { provider: 'provider' } }
    // }

    // // On check si on est arrivé ici suite à une connexion par provider (donc connecté)
    // if (to.query.provider) {
    //   // la logique des CGU
    //   const areCguValid = await SupabaseReadService.areUserCguValid()
    //   if (!areCguValid) {
    //     return true
    //   }
    //   else {
    //     return redirectFromUserStep(to) // car on n'est pas toApp mais to connexion?provider
    //   }
    // }

    // console.log('toResetPwd', toResetPwd)
    // if (toResetPwd) {
    //   return { path: '/reinitialisation-mot-de-passe' }
    //   // return { path: '/reinitialisation-mot-de-passe' }
    // }
    if (to.fullPath.includes('type=recovery')) {
      return { path: '/reinitialisation-mot-de-passe' }
    }

    if (to.fullPath.includes('type=signup')) {
      return redirectFromUserStep(to, step)
    }

    if (toApp) {
      if (toExpert) {
        if (authStore.isUserLoggedIn) {
          if (!fromApp) {
            if (authStore.returnUrl) {
              // this.$navigateTo({ name: `reinitialisation-mot-de-passe` })
              const returnUrl = authStore.returnUrl
              authStore.returnUrl = ''
              return returnUrl
            }
            else {
              return redirectFromUserStep(to, step)
            }
          }
          else {
            return true
          }
        }
        else {
          authStore.returnUrl = to.fullPath
          return { name: 'Connexion' }
        }
      }
      if (toClient) {
        // TODO le guard pour client
      }
    }
    else if (toConnexion && authStore.isUserLoggedIn) {
      // TODO voir comment on différencie un expert d'un client
      return redirectFromUserStep(to, step)
    }
  })
}

export function isRouteNameEquals (to: Nullable<RouteRecordName>, from: Nullable<RouteRecordName>) {
  if (isString(to) && isString(from)) return to.toLowerCase() === from.toLowerCase()
  return to === from
}

export function redirectFromUserStep (to: RouteLocationNormalized, step: number) {
  let redirectTo = ''
  switch (step) {
    case 1:
      redirectTo = 'App-Expert-Inscription-Infos'
      break
    case 2:
      redirectTo = 'App-Expert-Inscription-Expertise'
      break
    case 3:
      redirectTo = 'App-Expert-Inscription-Experiences'
      break
    case 4:
      redirectTo = 'App-Expert-Inscription-Metier'
      break
    case 5: {
      const fromMoulinette = sessionStorage.getItem('fromMoulinette')
      if (fromMoulinette && fromMoulinette !== null) {
        const fromMoulinetteSplittedAndShifted = fromMoulinette.split('/').filter((e: any) => e !== '')
        redirectTo = fromMoulinetteSplittedAndShifted.map((e: any) => e.charAt(0).toUpperCase() + e.slice(1))
          .join('-')
        sessionStorage.removeItem('fromMoulinette')
      }
      else {
        redirectTo = 'App-Expert-Inscription-Recap'
      }
      break
    }
    default:
      redirectTo = 'App-Expert-Inscription-Profil'
  }

  if (!isRouteNameEquals(to.name, redirectTo)) {
    consoleDebug(`redirectFromUserStep-redirectTo is: ${redirectTo}`)
    return { name: redirectTo }
  }
  return true
}
