import axios from 'axios'
import { kapixContext } from 'kapix-components-vue3'
import { isArray } from 'lodash-es'
import type { ITokenInfoLocalStorage } from './types'
import { TOKEN_KEY, baseUrl, getSupabaseApiKey, getSupabaseUrl, isDebug } from '~/constants'
import { bearer401ResponseInterceptor } from '~/axios'
import { useAuthStore } from '~/stores/auth'

const tokenInfoStorage = useStorage<Nullable<Partial<ITokenInfoLocalStorage>>>(TOKEN_KEY, {})

export const tokenInfo = {
  get tokenInfo () {
    return tokenInfoStorage.value
  },
  setToken (tokenInfo: Nullable<Partial<ITokenInfoLocalStorage>>) {
    tokenInfoStorage.value = tokenInfo || {}
  },
  get accessToken () {
    const { tokenInfo } = this
    return tokenInfo && tokenInfo.accessToken
  },
  get hasValidAccessToken () {
    const { accessToken, expirationToken } = this
    if (accessToken && expirationToken) {
      // https://stackoverflow.com/questions/14980014/how-can-i-calculate-the-time-between-2-dates-in-typescript
      const minutes = Math.floor(((expirationToken.valueOf() - new Date().valueOf()) / 1000) / 60)
      return minutes > 0
    }
    return false
  },
  get refreshToken () {
    const { tokenInfo } = this
    return tokenInfo && tokenInfo.refreshToken
  },
  get expirationToken () {
    const { tokenInfo } = this
    return tokenInfo && tokenInfo.expirationToken && new Date(tokenInfo.expirationToken)
  },
  get bearerAuthorization () {
    const { accessToken } = this
    return accessToken ? `Bearer ${accessToken}` : undefined
  },
  get bearerRefreshAuthorization () {
    const { refreshToken } = this
    return refreshToken ? `Bearer ${refreshToken}` : undefined
  }
}

export const backendAxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})
bearer401ResponseInterceptor(backendAxiosInstance)

export function registerTokenInfoLocalStorage (accessToken: string, refreshToken: string) {
  if (accessToken && refreshToken) {
    const tokenInfoLocalStorage = { refreshToken, accessToken, expirationToken: new Date(new Date().getTime() + 24 * 60 * 60000) }
    tokenInfo.setToken(tokenInfoLocalStorage) // 24h
  }
  else {
    throw new Error('Empty accessToken')
  }
}

export async function getConnectedUser () {
  const { accessToken } = tokenInfo
  if (accessToken) {
    const serverUser = await backendAxiosInstance.get<Kapix.Entity.IUser>(`${baseUrl}/auth/${accessToken}`)
    if (isDebug()) {
      // eslint-disable-next-line no-console
      console.log('accessToken', accessToken)
      // eslint-disable-next-line no-console
      console.log('Graphql headers', {
        Authorization: `Bearer ${accessToken}`
      })
    }
    return serverUser.data
  }
  else {
    throw new Error('Empty accessToken')
  }
}

export async function updateServerUser (user: Kapix.Entity.IUser) {
  const accessToken = tokenInfo.accessToken
  if (accessToken) {
    const response = await backendAxiosInstance.patch<Kapix.Entity.IUser>(`${baseUrl}/auth/updateUser`, user)
    return response.data
  }
  else {
    throw new Error('Empty accessToken')
  }
}

export async function serverLogoutUser (serverLogout = true) {
  const { accessToken } = tokenInfo
  if (accessToken) {
    tokenInfo.setToken(null)
    if (serverLogout) {
      try {
        const serverUser = await backendAxiosInstance.get(`${baseUrl}/auth/logout/${accessToken}`)
        return serverUser.data
      }
      catch (e) {}
    }
  }
}

export function getUrlTenantName (): string | null {
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.has('tenant')) {
    const tenantName = urlParams.get('tenant')
    if (tenantName) {
      return tenantName
    }
    return null
  }
  return null
}

export function getToken () {
  const authStore = useAuthStore()
  const accessToken = authStore.session?.access_token
  kapixContext.accessToken = accessToken
  tokenInfo.setToken({ accessToken })
  return accessToken
}

export async function getUserId (): Promise<number | undefined> {
  const response = await axios.get(`${getSupabaseUrl()}/rest/v1/user?select=id&apikey=${getSupabaseApiKey()}`, {
    headers: {
      authorization: `Bearer ${getToken()}`
    }
  })
  return isArray(response.data) ? response.data[0].id : undefined
}
