import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { version } from '../../package.json'
import { env, envIsStrictProduction, envIsStrictStaging } from '~/constants/env'
import { BUGSNAG } from '~/constants/bugsnag'
import type { UserModule } from '~/types'

export const install: UserModule = async ({ isClient, app }) => {
  const isProduction: boolean = (envIsStrictProduction() || envIsStrictStaging())

  if (!isClient || !isProduction) {
    return
  }

  Bugsnag.start({
    apiKey: BUGSNAG.API_KEY,
    plugins: [new BugsnagPluginVue()],
    appVersion: version,
    releaseStage: env.MODE
  })
  const bugsnagVue = Bugsnag.getPlugin('vue')
  if (bugsnagVue) app.use(bugsnagVue)
}
