{
  "pwa": {
    "updateInstalled": "Mise à jour installée",
    "updateAvailable": "Mise à jour disponible"
  },
  "authentication":{
    "success": "Connexion réussie",
    "warnings":{
      "email": "Veuillez renseigner une adresse mail",
      "password": "Veuillez renseigner un mot de passe"
    }
  },
  "errors":{
    "unauthorized": "Votre session est expirée, veuillez vous reconnecter",
    "backend": "Erreur de serveur, veuillez réessayer ou contacter l'assistance"
  }
}