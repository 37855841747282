import type { CustomStoreImplementationOptions, MergeCustomStore, StoreIdentifier } from 'kapix-components-vue3'
import { defineStore } from 'pinia'
import { appThemeToCssVars, createAppThemeCssVars, emitEvent, initAliveStoreIds, toResponsiveTheme } from 'kapix-components-vue3'
import appDataStoreStoreCustom from './appData.custom'
import appInfo from '~/app-info.json'
import { getI18n } from '~/modules/i18n'

const storeName = 'appData'
const customImplement: CustomStoreImplementationOptions = appDataStoreStoreCustom.options
const aliveStoreIds = initAliveStoreIds()

const themeSchema: KeyValuePair<Nullable<'fontFamily' | 'color' | 'gradientColor' | 'imageViewer'>> = {
  fontSize: null,
  fontFamily2: 'fontFamily',
  fontFamily: 'fontFamily',
  fontColor2: 'color',
  fontColor: 'color',
  fontHyperlinkColor: 'color',
  fontVisitedHyperlinkColor: 'color',
  buttonColor: 'color',
  buttonColor2: 'color',
  buttonBackgroundColor: 'gradientColor',
  buttonBackgroundColor2: 'gradientColor',
  backgroundColor: 'gradientColor',
  backgroundColor2: 'gradientColor',
  focusColor: 'color',
  focusBackgroundColor: 'gradientColor',
  primaryColor: 'color',
  secondaryColor: 'color',
  backgroundImage: 'imageViewer'
}

function appDataStoreFactory (storeId?: Nullable<StoreIdentifier>) {
  return defineStore(storeId == null ? storeName : `${storeName}/${storeId}`, {
    state: () => {
      return {
        $aliveStoreIds: aliveStoreIds,
        $subStoreIds: 1,
        $storeId: storeId,
        title: 'Site',
        icon: 'work',
        name: 'parcoursexpert',
        owner: 'frontend@matchers.fr',
        version: 'master',
        copyright: 'copyright © 2022',
        languages: [],
        website: {
          name: 'Matchers',
          description: 'Boostez votre entreprise en formant votre équipe',
          image: 'https://ucarecdn.com/ce9e3dcf-cf64-41a7-929a-fd7bb81623e6/',
          domain: 'www.matchers.fr',
          twitterImage: 'https://ucarecdn.com/bd846f8e-3bcb-41ef-8ab3-6ba70b53c3c0/',
          twitterCard: 'player'
        },
        pwa: {
          name: 'Matchers',
          shortName: 'Matchers',
          startUrl: './',
          themeColor: null,
          backgroundColor: null,
          image: 'https://ucarecdn.com/ce9e3dcf-cf64-41a7-929a-fd7bb81623e6/',
          description: 'Matchers, ce n\'est pas que de la formation'
        },
        platform: import.meta.env.MODE,
        rawTheme: {
          fontSize: 16,
          fontFamily2: 'Work Sans,sans-serif',
          fontFamily: 'google-font:Poppins',
          fontColor2: '#616161',
          fontColor: '#222222',
          fontHyperlinkColor: '#8AB4F8',
          fontVisitedHyperlinkColor: '#C58AF9',
          buttonColor: '#000000',
          buttonColor2: '#FFFFFF',
          buttonBackgroundColor: '#FFFFFF',
          buttonBackgroundColor2: '#000000',
          backgroundColor: null,
          backgroundColor2: null,
          focusColor: '#D93174FF',
          focusBackgroundColor: '#222222',
          primaryColor: '#00B4D8FF',
          secondaryColor: '#1100A2FF',
          backgroundImage: null
        },
        hostname: appInfo.hostname,
        twitter: appInfo.twitter as any,
        ...(customImplement.state && customImplement.state())
      }
    },
    getters: {
      /* @ts-ignore: to allow override in your custom file */
      theme: (state) => {
        return toResponsiveTheme(state.rawTheme)
      },
      /* @ts-ignore: to allow override in your custom file */
      themeCssVars: (state) => {
        return appThemeToCssVars(themeSchema, state.rawTheme)
      },
      /* @ts-ignore: to allow override in your custom file */
      locale: () => {
        return getI18n()?.locale?.value
      },
      ...customImplement.getters
    },
    actions: {
      /* @ts-ignore: to allow override in your custom file */
      init () {
        createAppThemeCssVars(this)

        watch(getI18n()?.locale, (locale) => {
          emitEvent('i18n.locale', locale)
        }, { immediate: true })
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        return storeId != null ? getStoreInstance(storeId) : this
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstances () {
        return aliveStoreIds.map(storeId => this.getStoreInstance(storeId))
      },
      /* @ts-ignore: to allow override in your custom file */
      newStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        const newStoreId = storeId || this.$subStoreIds++
        if (aliveStoreIds.includes(newStoreId)) {
          throw new Error(`Store with id ${storeId} already exists`)
        }
        aliveStoreIds.push(newStoreId)
        return getStoreInstance(newStoreId)
      },
      ...customImplement.actions
    }
  })
}

function getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
  return appDataStoreFactory(storeId)()
}

export const appDataStoreRaw = appDataStoreFactory()
export const appDataStore = () => appDataStoreRaw() as MergeCustomStore<typeof appDataStoreStoreCustom.instance>
