export const env = {
  NODE_ENV: import.meta.env.NODE_ENV,
  PROD: import.meta.env.PROD,
  MODE: import.meta.env.MODE, // taken from pnpm build -----> --mode=staging <----
  GIT_BRANCH: __GIT_BRANCH // see getEnv() in vite.config.ts
}

const productionStr = 'production'
const stagingStr = 'staging'

const likeProductionEnvs: string[] = [productionStr, stagingStr]

export function envIsLikeProduction (): boolean {
  return likeProductionEnvs.includes(env.MODE)
}

export function envIsStrictProduction (): boolean {
  return env.MODE === productionStr
}

/* check if comes from build:staging AND if the branch is 'main' or if the branch must be 'forced' as a strict staging environment  */
export function envIsStrictStaging (): boolean {
  let isStrictStaging = false

  if (env.GIT_BRANCH) {
    isStrictStaging = (env.GIT_BRANCH === 'main' || env.GIT_BRANCH.match(/.*env-staging/g) != null)
  }

  return (env.MODE === stagingStr && isStrictStaging)
}
