import type { AxiosInstance } from 'axios'
import { useToast } from 'kapix-components-vue3'
import { HttpStatusCode } from '~/constants'
import { tokenInfo } from '~/helpers/server'
import { appAuthenticationStore } from '~/stores'

export function bearer401ResponseInterceptor (axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use((config) => {
    if (!config.headers) {
      config.headers = {}
    }
    config.headers.Authorization = tokenInfo.bearerAuthorization
    return config
  }, (error) => {
    // Do something with request error
    return Promise.reject(error)
  })
  axiosInstance.interceptors.response.use(
    (response) => { return response },
    async (error) => {
      const { config, response } = error
      if (response && (response.status === HttpStatusCode.Unauthorized)) {
        if (!config.headers.Retry) {
          try {
            await appAuthenticationStore().refresh()
          }
          catch {
            useToast().error('Votre session a expiré, veuillez vous reconnecter.')
            appAuthenticationStore().logout(false)
          }
          config.headers.Authorization = tokenInfo.bearerAuthorization || ''
          config.headers.Retry = true
          return axiosInstance({
            ...config,
            ...{
              headers: config.headers.toJSON()
            }
          })
        }
      }
    }
  )
}
