import type { CustomStoreImplementationOptions, KaElementMap, MergeCustomStore, StoreIdentifier } from 'kapix-components-vue3'
import { defineStore } from 'pinia'
import type { WritableComputedRef } from 'vue'
import { NOOP, initAliveStoreIds, kapixContext, remove } from 'kapix-components-vue3'
import blocReassuranceAvisX9ComponentStoreCustom from './store.custom'

const storeName = 'blocReassuranceAvisX9Component'
const customImplement: CustomStoreImplementationOptions = blocReassuranceAvisX9ComponentStoreCustom.options
const aliveStoreIds = initAliveStoreIds()

function blocReassuranceAvisX9ComponentFactory (storeId?: Nullable<StoreIdentifier>) {
  return defineStore(storeId == null ? storeName : `${storeName}/${storeId}`, {
    state: () => {
      return {
        $aliveStoreIds: aliveStoreIds,
        $subStoreIds: 1,
        $storeId: storeId,
        $emit: (() => {}) as (propName: any, value: any) => void,
        $elements: {} as KaElementMap,
        $router: kapixContext.isClient ? kapixContext.$router : undefined,
        $route: kapixContext.isClient ? kapixContext.$route : undefined,
        $parentPage: NOOP,
        $parentComponent: NOOP,
        // Constants,
        $constants: {},
        // Data,
        $data: {
          picture01: undefined as Nullable<string | File>,
          customer01: undefined as Nullable<string>,
          testimonial01: undefined as Nullable<string>,
          picture02: undefined as Nullable<string | File>,
          customer02: undefined as Nullable<string>,
          testimony02: undefined as Nullable<string>,
          picture03: undefined as Nullable<string | File>,
          customer03: undefined as Nullable<string>,
          testimonial03: undefined as Nullable<string>,
          picture04: undefined as Nullable<string | File>,
          customer04: undefined as Nullable<string>,
          testimony04: undefined as Nullable<string>,
          picture05: undefined as Nullable<string | File>,
          customer05: undefined as Nullable<string>,
          testimony05: undefined as Nullable<string>,
          picture06: undefined as Nullable<string | File>,
          customer06: undefined as Nullable<string>,
          testimony06: undefined as Nullable<string>,
          picture07: undefined as Nullable<string | File>,
          customer07: undefined as Nullable<string>,
          testimony07: undefined as Nullable<string>,
          picture08: undefined as Nullable<string | File>,
          testimony08: undefined as Nullable<string>,
          customer08: undefined as Nullable<string>
        },
        ...(customImplement.state && customImplement.state())
      }
    },
    getters: {
      /* @ts-ignore: to allow override in your custom file */
      $picture01 (): WritableComputedRef<Nullable<string | File>> {
        return computed({
          get: () => this.$data.picture01,
          set: (value) => {
            this.$data.picture01 = value
            this.$emit('update:picture01', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $customer01 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.customer01,
          set: (value) => {
            this.$data.customer01 = value
            this.$emit('update:customer01', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $testimonial01 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.testimonial01,
          set: (value) => {
            this.$data.testimonial01 = value
            this.$emit('update:testimonial01', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $picture02 (): WritableComputedRef<Nullable<string | File>> {
        return computed({
          get: () => this.$data.picture02,
          set: (value) => {
            this.$data.picture02 = value
            this.$emit('update:picture02', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $customer02 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.customer02,
          set: (value) => {
            this.$data.customer02 = value
            this.$emit('update:customer02', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $testimony02 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.testimony02,
          set: (value) => {
            this.$data.testimony02 = value
            this.$emit('update:testimony02', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $picture03 (): WritableComputedRef<Nullable<string | File>> {
        return computed({
          get: () => this.$data.picture03,
          set: (value) => {
            this.$data.picture03 = value
            this.$emit('update:picture03', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $customer03 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.customer03,
          set: (value) => {
            this.$data.customer03 = value
            this.$emit('update:customer03', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $testimonial03 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.testimonial03,
          set: (value) => {
            this.$data.testimonial03 = value
            this.$emit('update:testimonial03', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $picture04 (): WritableComputedRef<Nullable<string | File>> {
        return computed({
          get: () => this.$data.picture04,
          set: (value) => {
            this.$data.picture04 = value
            this.$emit('update:picture04', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $customer04 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.customer04,
          set: (value) => {
            this.$data.customer04 = value
            this.$emit('update:customer04', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $testimony04 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.testimony04,
          set: (value) => {
            this.$data.testimony04 = value
            this.$emit('update:testimony04', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $picture05 (): WritableComputedRef<Nullable<string | File>> {
        return computed({
          get: () => this.$data.picture05,
          set: (value) => {
            this.$data.picture05 = value
            this.$emit('update:picture05', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $customer05 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.customer05,
          set: (value) => {
            this.$data.customer05 = value
            this.$emit('update:customer05', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $testimony05 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.testimony05,
          set: (value) => {
            this.$data.testimony05 = value
            this.$emit('update:testimony05', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $picture06 (): WritableComputedRef<Nullable<string | File>> {
        return computed({
          get: () => this.$data.picture06,
          set: (value) => {
            this.$data.picture06 = value
            this.$emit('update:picture06', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $customer06 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.customer06,
          set: (value) => {
            this.$data.customer06 = value
            this.$emit('update:customer06', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $testimony06 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.testimony06,
          set: (value) => {
            this.$data.testimony06 = value
            this.$emit('update:testimony06', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $picture07 (): WritableComputedRef<Nullable<string | File>> {
        return computed({
          get: () => this.$data.picture07,
          set: (value) => {
            this.$data.picture07 = value
            this.$emit('update:picture07', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $customer07 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.customer07,
          set: (value) => {
            this.$data.customer07 = value
            this.$emit('update:customer07', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $testimony07 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.testimony07,
          set: (value) => {
            this.$data.testimony07 = value
            this.$emit('update:testimony07', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $picture08 (): WritableComputedRef<Nullable<string | File>> {
        return computed({
          get: () => this.$data.picture08,
          set: (value) => {
            this.$data.picture08 = value
            this.$emit('update:picture08', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $testimony08 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.testimony08,
          set: (value) => {
            this.$data.testimony08 = value
            this.$emit('update:testimony08', value)
          }
        })
      },
      /* @ts-ignore: to allow override in your custom file */
      $customer08 (): WritableComputedRef<Nullable<string>> {
        return computed({
          get: () => this.$data.customer08,
          set: (value) => {
            this.$data.customer08 = value
            this.$emit('update:customer08', value)
          }
        })
      },
      ...customImplement.getters
    },
    actions: {
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        return storeId != null ? getStoreInstance(storeId) : this
      },
      /* @ts-ignore: to allow override in your custom file */
      getStoreInstances () {
        return aliveStoreIds.map(storeId => this.getStoreInstance(storeId))
      },
      /* @ts-ignore: to allow override in your custom file */
      newStoreInstance (storeId?: Nullable<StoreIdentifier>) {
        const newStoreId = storeId || this.$subStoreIds++
        if (aliveStoreIds.includes(newStoreId)) {
          throw new Error(`Store with id ${storeId} already exists`)
        }
        aliveStoreIds.push(newStoreId)
        return getStoreInstance(newStoreId)
      },
      ...customImplement.actions,
      /* @ts-ignore: to allow override in your custom file */
      beforeMount () {
        customImplement.actions?.beforeMount?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      mounted () {
        customImplement.actions?.mounted?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      async serverPrefetch () {
        await customImplement.actions?.serverPrefetch?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      beforeUnmount () {
        customImplement.actions?.beforeUnmount?.call(this)
      },
      /* @ts-ignore: to allow override in your custom file */
      unmounted () {
        if (this.$storeId != null) {
          this.$dispose()
          remove(aliveStoreIds, this.$storeId)
        }
        customImplement.actions?.unmounted?.call(this)
      }
    }
  })
}

function getStoreInstance (storeId?: Nullable<StoreIdentifier>) {
  return blocReassuranceAvisX9ComponentFactory(storeId)()
}

export const blocReassuranceAvisX9ComponentRaw = blocReassuranceAvisX9ComponentFactory()
export const blocReassuranceAvisX9Component = () => blocReassuranceAvisX9ComponentRaw() as MergeCustomStore<typeof blocReassuranceAvisX9ComponentStoreCustom.instance>
