import { SUPABASE } from './supabase'

export const TOKEN_KEY = 'user-token-info'

export function isDebug () {
  return import.meta.env.MODE === 'development'
}

export function isProduction () {
  return import.meta.env.MODE === 'production'
}

export function isStaging () {
  return import.meta.env.MODE === 'staging'
}

function getBaseUrl () {
  if (isDebug()) return 'http://localhost:3001'
}

export function getSupabaseUrl () {
  return SUPABASE.URL
}

export function getSupabaseApiKey () {
  return SUPABASE.KEY
}

export const BASE_URL = getBaseUrl()
export const baseUrl = `${BASE_URL}/api`

export * from './http'
