import { createClient } from '@supabase/supabase-js'
import type { Database } from '../../../supabase/db.types'
import { SUPABASE } from '~/constants/supabase'

const supabaseUrl = SUPABASE.URL as string
const supabaseAnonKey = SUPABASE.KEY as string

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    // persistSession: true,
    detectSessionInUrl: true
  }
})
